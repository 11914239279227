import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import CarouselVideo from './CarouselVideo'
import 'swiper/css/navigation'
import { Navigation, Virtual, Autoplay } from 'swiper'
import { useWindowDimensions } from '../utils/hooks'

const CarouselWithSwiper = ({ videos }) => {
	const isFullScreen = false

	const screenSize = useWindowDimensions()
	return (
		<Swiper
			className="w-full"
			autoplay={{
				delay: 11000,
			}}
			navigation
			// virtual={{
			// 	// addSlidesAfter: 1,
			// 	// addSlidesBefore: 1,
			// 	enabled: true,
			// 	cache: false,
			// }}
			modules={[Navigation, Autoplay]}
		>
			{videos.map(
				(
					{
						video: {
							frontmatter: {
								desktopSource,
								mobileSource,
								fullSource,
								title,
								director,
								text,
							},
							id,
						},
					},
					index
				) => {
					return (
						<SwiperSlide key={id} virtualIndex={index}>
							<CarouselVideo
								desktopSource={desktopSource}
								mobileSource={mobileSource}
								title={title}
								fullSource={fullSource}
								director={director}
								text={text}
								isFullScreen={isFullScreen}
								className={`
                                    relative h-screen
                                    w-screen cursor-pointer
                                    bg-black brightness-90
                                    filter`}
								captionClassName={`
                                    absolute left-[2rem] lg:left-[5rem] top-[63%] lg:top-1/3
                                    text-5xl text-left lg:text-8xl font-light text-white
                                    flex flex-col font-light whitespace-wrap
                                    z-40 max-w-max pr-4`}
							/>
						</SwiperSlide>
					)
				}
			)}
		</Swiper>
	)
}

export default CarouselWithSwiper
