import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import CarouselWithSwiper from '../components/CarouselWithSwiper'
import { FullscreenVideoProvider } from '../utils/useFullscreenVideo'

const IndexPageTemplate = ({ videos }) => {
	return (
		<main className="col-span-4 col-start-1 row-start-1 row-end-3 w-full lg:row-end-4">
			<FullscreenVideoProvider>
				<CarouselWithSwiper videos={videos} />
			</FullscreenVideoProvider>
		</main>
	)
}

const IndexPage = ({ data, location }) => {
	return (
		<Layout location={location}>
			<IndexPageTemplate
				videos={data.markdownRemark.frontmatter.carousel_items}
			/>
		</Layout>
	)
}

export const data = graphql`
	query IndexPageQuery {
		markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
			fields {
				slug
			}
			frontmatter {
				carousel_items {
					video {
						id
						frontmatter {
							title
							text
							director
							desktopSource {
								source
								poster
							}
							mobileSource {
								source
								poster
							}
							fullSource
						}
					}
				}
			}
			id
		}
	}
`

export default IndexPage
